import React from "react";
import "./Frame1155.css";

function Frame1155() {
  return (
    <div className="frame-115-102">
      <div className="decisional-model-8 valign-text-middle inter-medium-white-18px">Decisional Model</div>
    </div>
  );
}

export default Frame1155;
