import React from "react";
import "./Frame11532.css";

function Frame11532() {
  return (
    <div className="frame-115-96">
      <div className="new-case-1 valign-text-middle inter-medium-star-dust-18px">New case</div>
    </div>
  );
}

export default Frame11532;
