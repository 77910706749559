import React from "react";
import "./Frame1154.css";

function Frame1154() {
  return (
    <div className="frame-115-104">
      <div className="kaizen valign-text-middle inter-medium-star-dust-18px">Kaizen</div>
    </div>
  );
}

export default Frame1154;
