import React from "react";
import "./Frame1175.css";

function Frame1175() {
  return (
    <div className="frame-117-95">
      <div className="kanban-12 valign-text-middle inter-medium-white-18px">Kanban</div>
    </div>
  );
}

export default Frame1175;
