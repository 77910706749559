import React from "react";
import "./Frame12.css";

function Frame12() {
  return (
    <a href="#rectangle-2">
      <div className="frame-6-2"></div>
    </a>
  );
}

export default Frame12;
