import React from "react";
import { Link } from "react-router-dom";
import NavItems3 from "../NavItems3";
import Frame11332 from "../Frame11332";
import Frame114 from "../Frame114";
import Frame115 from "../Frame115";
import Frame117 from "../Frame117";
import "./CurPerformance.css";
import { s_sum } from "../Social6";
import { e_sum } from "../Environmental5";
import { m_sum } from "../Modernization4";
import { l_sum } from "../LeadTime2";
import { c_sum } from "../Cost2";
import { q_sum } from "../Quality6";
import { cc_sum } from "../ClientConsommateur2";
import React, { useEffect, useState } from "react";

function CurPerformance(props) {
  const calculateAmeliorationPercentage = (current, previous) => {
    if (previous === 0) {
      return "N/A"; // Handle division by zero
    }
    return (((current - previous) / previous) * 100).toFixed(2) + "%";
  };
  const {
    x1200PxLogo_Icam__20081,
    resultPropositions,
    place,
    formalismesEnFonct,
    navItems3Props,
    frame11332Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="cur-performance-10 screen">
        <div className="overlap-group-91">
          <Link to="/home">
            <img
              className="x1200px-logo_icam_-_2008-1-88"
              src={x1200PxLogo_Icam__20081}
              alt="1200px-Logo_ICAM_-_2008 1"
            />
          </Link>
          <div className="nav-items-container-52">
            <NavItems3
              className={navItems3Props.className}
              frame1172Props={navItems3Props.frame1172Props}
              frame1162Props={navItems3Props.frame1162Props}
              frame1132Props={navItems3Props.frame1132Props}
            />
            <div className="nav-items-2-56">
              <Frame11332 className={frame11332Props.className} />
              <Frame114 />
              <Frame115 />
              <div className="frame-116-181">
                <div className="result-propositions valign-text-middle inter-medium-white-18px">
                  {resultPropositions}
                </div>
              </div>
              <Frame117 />
            </div>
          </div>
        </div>
        <div className="flex-row-154">
          <Link to="/analysis">
            <div className="frame-5-25">
              <div className="place-273 valign-text-middle inter-bold-tropical-rain-forest-18px">
                {place}
              </div>
            </div>
          </Link>
          <p className="formalismes-en-fonct-1 valign-text-middle bevan-normal-white-20px">
            {formalismesEnFonct}
          </p>
        </div>
        <div className="container1">
          <div className="container2">
            <h2>Previous Results</h2>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Client/ Consomateur: 10/17
            </label>

            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Environment: 15/63
            </label>

            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Quality: 18/81
            </label>

            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Social: 19/38
            </label>

            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Modernization: 17/26
            </label>

            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Lead Time: 17/18
            </label>

            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Cost: 17/22
            </label>
          </div>
          <div className="container3">
            <h2>Current Results</h2>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Client/ Consomateur: {cc_sum}/17
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Environment: {e_sum}/63
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Quality: {q_sum}/81
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Social: {s_sum}/38
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Modernization: {m_sum}/26
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Lead Time: {l_sum}/18
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Cost: {c_sum}/22
            </label>
          </div>
          <div className="container4">
            <h2>Amelioration percentage</h2>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Client/ Consomateur:{calculateAmeliorationPercentage(cc_sum, 17)}
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Environment:{calculateAmeliorationPercentage(e_sum, 17)}
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Quality:{calculateAmeliorationPercentage(q_sum, 17)}
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Social:{calculateAmeliorationPercentage(s_sum, 17)}
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Modernization:{calculateAmeliorationPercentage(m_sum, 17)}
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Lead Time:{calculateAmeliorationPercentage(l_sum, 17)}
            </label>
            <label className="x25-4 inter-bold-rum-swizzle-56px" htmlFor="25">
              Cost: {calculateAmeliorationPercentage(c_sum, 17)}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurPerformance;
