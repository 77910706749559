import React from "react";
import "./Frame1157.css";

function Frame1157() {
  return (
    <div className="frame-115-117">
      <div className="kaizen-10 valign-text-middle inter-medium-white-18px">Kaizen</div>
    </div>
  );
}

export default Frame1157;
