import React from "react";
import "./Frame11632.css";

function Frame11632() {
  return (
    <div className="frame-116-84">
      <div className="physical-model valign-text-middle inter-medium-star-dust-18px">Physical Model</div>
    </div>
  );
}

export default Frame11632;
