import React from "react";
import { Link, useHistory } from "react-router-dom";
import NavItems3 from "../NavItems3";
import NavItems2 from "../NavItems2";
import Toggle from "../Toggle";
import "./ClientConsommateur2.css";
import jsPDF from "jspdf";
import { cc_allData1, cc_yesCount1 } from "../ClientConsommateur1";
import { s_sum } from "../Social6";
import { company_name } from "../Aquisition";
import { e_sum } from "../Environmental5";
import { s_sum } from "../Social6";
import { m_sum } from "../Modernization4";
import { l_sum } from "../LeadTime2";
import { c_sum } from "../Cost2";
import { q_sum } from "../Quality6";
export let cc_sum = 0;

function ClientConsommateur2(props) {
  const {
    x1200PxLogo_Icam__20081,
    navItems3Props,
    toggle1Props,
    toggle2Props,
    toggle3Props,
  } = props;

  let cc_yesCount2 = 0;
  function clicked() {
    const button = event.target; // Récupère le bouton qui a été cliqué
    if (button.classList.contains("frame-4-5")) {
      // Vérifie si c'est un bouton 'oui'
      cc_yesCount2++; // Incrémente le compteur de bouton 'Oui'
      console.log(`Number of yesButton selected: ${cc_yesCount2}`); // Affiche le nombre de boutons 'Oui' sélectionnés
      button.disabled = true; // Désactive le bouton 'oui' qui a été cliqué
      button.classList.add("enabled"); // Ajoute la classe 'enabled' au bouton 'oui'
    } else if (button.classList.contains("frame-4-6")) {
      // Vérifie si c'est un bouton 'non'
      button.disabled = true; // Désactive le bouton 'non' qui a été cliqué
      button.classList.add("enabled"); // Désactive le bouton 'non' qui a été cliqué
    }
  }

  const history = useHistory();
  let cc_allData2 = [];

  function saveData() {
    const sections = document.getElementsByTagName("section");
    const data = [];

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const question = section.innerText.split("\n")[0];
      const enabledValue = section.querySelector(".enabled").value;
      const questionObj = {
        question: question,
        answer: enabledValue,
      };

      data.push(questionObj);
    }

    cc_allData2 = cc_allData2.concat(data); // Concatenate the new data to cc_allData2
    const cc_allData = cc_allData1.concat(cc_allData2); // Update cc_allData

    cc_sum = cc_yesCount1 + cc_yesCount2;
    console.log(cc_allData2);

    console.log(cc_allData);

    generatePdf(cc_allData); // Call the generatePdf function with the updated array
  }

  function generatePdf(cc_allData) {
    // Créer un nouveau document PDF
    const doc = new jsPDF();
    const lineHeight = 20;
    const pageHeight = doc.internal.pageSize.height - 40;
    let y = 20;

    // Parcourir les données dans cc_allData et ajouter chaque question et réponse au document PDF
    cc_allData.forEach((data, index) => {
      const question = `Question ${index + 1}: ${data.question}`;
      const answer = `Réponse: ${data.answer}`;

      // Vérifier si la position y actuelle plus la hauteur de la question dépasse la hauteur maximale de la page
      if (y + lineHeight > pageHeight) {
        // Ajouter une nouvelle page et réinitialiser la position y
        doc.addPage();
        y = 20;
      }

      // // Ajouter la question et la réponse au document PDF
      // doc.text(question, 10, y);
      // y += lineHeight; // Mettre à jour la position y pour la prochaine ligne

      // Séparer la question sur plusieurs lignes si elle est trop longue
      const splitQuestion = doc.splitTextToSize(
        question,
        doc.internal.pageSize.width - 20
      );
      doc.text(splitQuestion, 10, y);
      y += splitQuestion.length * lineHeight;

      doc.text(answer, 10, y);
      y += lineHeight; // Mettre à jour la position y pour la prochaine ligne
    });

    const data = {
      enterprise: { company_name },
      Social: {
        Total: { s_sum },
      },
      Environnement: {
        Total: { e_sum },
      },
      Quality: {
        Total: { q_sum },
      },
      Cost: {
        Total: { c_sum },
      },
      Lead_time: {
        Total: { l_sum },
      },
      Modernisation: {
        Total: { m_sum },
      },
      ClientConsommateur: {
        Total: { cc_sum },
      },
    };

    fetch("https://scgreenoptimizer.fr/save-points", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error saving data to MongoDB");
        }
      })
      .catch((error) => {
        console.error(error);
        // Gérer l'erreur
      });

    history.push("/cur-performances");
    // Enregistrer le document PDF
    doc.save("Client/consommateur_Criteria.pdf");
  }

  return (
    <div className="container-center-horizontal">
      <div className="client-consommateur-2 screen">
        <div className="overlap-group-26">
          <Link to="/home">
            <img
              className="x1200px-logo_icam_-_2008-1-26"
              src={x1200PxLogo_Icam__20081}
              alt="1200px-Logo_ICAM_-_2008 1"
            />
          </Link>
          <div className="nav-items-container-7">
            <NavItems3
              className={navItems3Props.className}
              frame1172Props={navItems3Props.frame1172Props}
              frame1162Props={navItems3Props.frame1162Props}
              frame1132Props={navItems3Props.frame1132Props}
            />
            <NavItems2 />
          </div>
        </div>
        <div className="emploirelation-employeur-employ">
          <div className="emploi-relation-employeur-employ">
            <Toggle
              className={toggle1Props.className}
              buttonSecondaryProps={toggle1Props.buttonSecondaryProps}
            />
            <section>
              <label
                className="is-your-complaint-rate-above-75 subtitel"
                htmlFor="isyourcomplaintrateabove75"
              >
                Is your complaint rate above 75%?
              </label>
              <div className="ouinon-45 ouinon-1-1">
                <input
                  onClick={clicked}
                  type="button"
                  value="Yes"
                  className="frame-4-5"
                ></input>
                <input
                  onClick={clicked}
                  type="button"
                  value="No"
                  className="frame-4-6"
                ></input>
              </div>
            </section>
            <section>
              <label
                className="is-your-complaint-rate-between-50-and-75 subtitel"
                htmlFor="isyourcomplaintratebetween50and75"
              >
                Is your complaint rate between 50% and 75%?
              </label>
              <div className="ouinon-45 ouinon-1-1">
                <input
                  onClick={clicked}
                  type="button"
                  value="Yes"
                  className="frame-4-5"
                ></input>
                <input
                  onClick={clicked}
                  type="button"
                  value="No"
                  className="frame-4-6"
                ></input>
              </div>
            </section>
            <section>
              <label
                className="is-your-complaint-rate-be subtitel"
                htmlFor="isyourcomplaintratebetween25and50"
              >
                Is your complaint rate between 25% and 50%?
              </label>
              <div className="ouinon-45 ouinon-1-1">
                <input
                  onClick={clicked}
                  type="button"
                  value="Yes"
                  className="frame-4-5"
                ></input>
                <input
                  onClick={clicked}
                  type="button"
                  value="No"
                  className="frame-4-6"
                ></input>
              </div>
            </section>
            <section>
              <label
                className="is-your-complaint-rate-be subtitel"
                htmlFor="isyourcomplaintratebelow25"
              >
                Is your complaint rate below 25%?
              </label>
              <div className="ouinon-45 ouinon-1-1">
                <input
                  onClick={clicked}
                  type="button"
                  value="Yes"
                  className="frame-4-5"
                ></input>
                <input
                  onClick={clicked}
                  type="button"
                  value="No"
                  className="frame-4-6"
                ></input>
              </div>
            </section>
          </div>
          <div className="emploi-relation-employeur-employ">
            <Toggle
              className={toggle2Props.className}
              buttonSecondaryProps={toggle2Props.buttonSecondaryProps}
            />
            <section>
              <label
                className="is-your-complaint-re subtitel"
                htmlFor="isyourcomplaintresponsetimelessthanorequalto14days"
              >
                Is your complaint response time less than or equal to 14 days?
              </label>
              <div className="ouinon-45 ouinon-1-1">
                <input
                  onClick={clicked}
                  type="button"
                  value="Yes"
                  className="frame-4-5"
                ></input>
                <input
                  onClick={clicked}
                  type="button"
                  value="No"
                  className="frame-4-6"
                ></input>
              </div>
            </section>
            <section>
              <label
                className="is-your-claim-respon subtitel"
                htmlFor="isyourclaimresponsetimebetween14and21days"
              >
                Is your claim response time between 14 and 21 days?
              </label>
              <div className="ouinon-45 ouinon-1-1">
                <input
                  onClick={clicked}
                  type="button"
                  value="Yes"
                  className="frame-4-5"
                ></input>
                <input
                  onClick={clicked}
                  type="button"
                  value="No"
                  className="frame-4-6"
                ></input>
              </div>
            </section>
            <section>
              <label
                className="is-your-c subtitel"
                htmlFor="isyourclaimresponsetimebetween21and330days"
              >
                Is your claim response time between 21 and 330 days?
              </label>
              <div className="ouinon-45 ouinon-1-1">
                <input
                  onClick={clicked}
                  type="button"
                  value="Yes"
                  className="frame-4-5"
                ></input>
                <input
                  onClick={clicked}
                  type="button"
                  value="No"
                  className="frame-4-6"
                ></input>
              </div>
            </section>
            <section>
              <label
                className="is-your-c subtitel"
                htmlFor="isyourcomplaintresponsetimelongerthan30days"
              >
                is your complaint response time longer than 30 days?
              </label>
              <div className="ouinon-45 ouinon-1-1">
                <input
                  onClick={clicked}
                  type="button"
                  value="Yes"
                  className="frame-4-5"
                ></input>
                <input
                  onClick={clicked}
                  type="button"
                  value="No"
                  className="frame-4-6"
                ></input>
              </div>
            </section>
          </div>
        </div>
        <div className="flex-row-73">
          <Toggle
            className={toggle3Props.className}
            buttonSecondaryProps={toggle3Props.buttonSecondaryProps}
          />
          <button className="frame-113-69">
            <div
              onClick={saveData}
              className="go-on-to-the-next-step-1 valign-text-middle inter-bold-mineral-green-18px"
            >
              Go to results
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClientConsommateur2;
