import React from "react";
import "./Frame1173.css";

function Frame1173() {
  return (
    <div className="frame-117-73">
      <div className="process-view-8 valign-text-middle inter-medium-white-18px">Process view</div>
    </div>
  );
}

export default Frame1173;
