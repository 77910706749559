import React from "react";
import "./Frame1163.css";

function Frame1163() {
  return (
    <div className="frame-116-155">
      <div className="physical-model-8 valign-text-middle inter-medium-white-18px">Physical Model</div>
    </div>
  );
}

export default Frame1163;
