import React from "react";
import "./Frame1147.css";

function Frame1147() {
  return (
    <div className="frame-114-110">
      <div className="x5-s-12 valign-text-middle inter-medium-white-18px">5S</div>
    </div>
  );
}

export default Frame1147;
