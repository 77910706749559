import React from "react";
import { Link } from "react-router-dom";
import HeaderMenuDefault from "../HeaderMenuDefault";
import Frame11432 from "../Frame11432";
import Frame11532 from "../Frame11532";
import Frame11722 from "../Frame11722";
import Frame11622 from "../Frame11622";
import Frame11322 from "../Frame11322";
import Frame1 from "../Frame1";
import Frame12 from "../Frame12";
import "./Home.css";

function Home(props) {
  const {
    x1200PxLogo_Icam__20081,
    takeYourCompanyToTheNextLevel,
    discoverFromDiffer,
    newCase,
    existingCase,
    referenceCase,
    headerMenuDefaultProps,
    frame11722Props,
    frame11622Props,
    frame11Props,
    frame12Props,
    frame13Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <form
        className="home screen"
        onclick="window.open('existing-case.html', '_self');"
        name="form5"
        action="form5"
        method="post"
      >
        <div className="flex-row-4">
          <div className="flex-col-2">
            <Link to="/home">
              <img
                className="x1200px-logo_icam_-_2008-1-1"
                src={x1200PxLogo_Icam__20081}
                alt="1200px-Logo_ICAM_-_2008 1"
              />
            </Link>
            <div className="overlap-group2-1">
              <h1 className="take-your-company-to-the-next-level valign-text-middle">
                {takeYourCompanyToTheNextLevel}
              </h1>

              <p className="discover-from-differ">{discoverFromDiffer}</p>
            </div>
            <a
              href="javascript:SubmitForm('form5')"
              className="align-self-flex-center"
            >
              <div className="frame-2-1">
                <HeaderMenuDefault>
                  {headerMenuDefaultProps.children}
                </HeaderMenuDefault>
              </div>
            </a>
          </div>
          <div className="flex-col-3">
            <div className="nav-items-3">
              <Frame11432 />
              <Frame11532 />
              <Frame11722>{frame11722Props.children}</Frame11722>
              <Frame11622>{frame11622Props.children}</Frame11622>
              <Frame11322 />
            </div>
            <div className="overlap-group-container">
              <div className="overlap-group1-18">
                <a href="javascript:SubmitForm('form5')">
                  <div className="frame-3">
                    <Frame1 src={frame11Props.src} />
                  </div>
                </a>
                <Link to="/aquisition">
                  <div className="new-case valign-text-middle poppins-medium-mine-shaft-24px">
                    {newCase}
                  </div>
                </Link>
              </div>
              <div className="overlap-group4-1">
                <Link to="/existing-case">
                  <div className="group-79">
                    <Frame12 />
                  </div>
                </Link>
                <Link to="/existing-case">
                  <div className="group-80">
                    <Frame1
                      src={frame12Props.src}
                      className={frame12Props.className}
                    />
                  </div>
                </Link>
                <Link to="/existing-case">
                  <div className="existing-case-62 valign-text-middle poppins-medium-mine-shaft-24px">
                    {existingCase}
                  </div>
                </Link>
              </div>
              <div className="overlap-group3-1">
                <Link to="/cur-performances">
                  <div className="frame-3-1">
                    <Frame1
                      src={frame13Props.src}
                      className={frame13Props.className}
                    />
                  </div>
                </Link>
                <Link to="/cur-performances">
                  <div className="reference-case valign-text-middle poppins-medium-mine-shaft-24px">
                    {referenceCase}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Home;
