import React from "react";
import "./Frame52.css";

function Frame52() {
  return (
    <div className="frame-5-11">
      <div className="place-79 valign-text-middle inter-bold-tropical-rain-forest-18px">Back</div>
    </div>
  );
}

export default Frame52;
